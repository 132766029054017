require('../css/app.css');

// require jQuery normally
var $ = require('jquery');
// create global $ and jQuery variables
global.$ = global.jQuery = $;

import '../css/app.scss';
import 'bootstrap';
import 'bootstrap-notify';
import 'bootstrap-select';


/*import 'tarteaucitronjs/tarteaucitron';
require('../../node_modules/tarteaucitronjs/tarteaucitron.js');*/
import '../js/cookies/dist/cookies-message.min.js'
import '../js/cookies/dist/cookies-message.min.css'
import "../../node_modules/admin-lte/bower_components/bootstrap-datepicker/js/bootstrap-datepicker.js";

import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min.js';
import 'bootstrap-select/dist/js/i18n/defaults-fr_FR.min';

//import 'bs-custom-file-input/dist/bs-custom-file-input.min';
import bsCustomFileInput from 'bs-custom-file-input'


function initDatepicker(){
	$('.datepicker').datepicker({
        format: "dd/mm/yyyy",
        autoclose: true,
        todayHighlight: true,
        language: 'fr'
    });
}



function generateNotify($title, $message, $icon, $type){
 	$.notify({
		icon: $icon,
		title: $title,
		message: $message,
		target: '_blank'
	},{
		element: 'body',
		position: null,
		type: $type,
		allow_dismiss: true,
		newest_on_top: false,
		showProgressbar: false,
		placement: {
			from: "top",
			align: "center"
		},
		offset: 100,
		spacing: 20,
		z_index: 1031,
		delay: 5000,
		timer: 1000,
		url_target: '_blank',
		mouse_over: null,
		animate: {
			enter: 'animated fadeInDown',
			exit: 'animated fadeOutUp'
		},
		onShow: null,
		onShown: null,
		onClose: null,
		onClosed: null,
		icon_type: 'class',
		template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
			'<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
			'<span data-notify="icon"></span> ' +
			'<span data-notify="title">{1}</span> ' +
			'<span data-notify="message">{2}</span>' +
			'<div class="progress" data-notify="progressbar">' +
				'<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
			'</div>' +
			//'<a href="{3}" target="{4}" data-notify="url"></a>' +
		'</div>' 
	});

}



$( document ).ready(function() {

	var url = window.location.search;

	if(url.match('page')){
		if (url.match('page').length > 0) {
			$('html, body').animate({
				scrollTop: $("#results").offset().top
			}, 800);
			
		}
	}

	initDatepicker();
	$('.bootstrap-select').selectpicker({ language: 'FR' });

	bsCustomFileInput.init();

	$(document).on('change','div#inscription input[name="particulier"]',function(e){
		var typeForm = $(this).val();
		var url = $(this).attr('data-url');

		$.ajax( {
	    	type: 'GET',
	      	url: url,
		    data: {'type' : typeForm},
		    dataType : "json",
	      	success: function( response ) {
	      		$("#appendForm").html(response.html)
	      	}
	    });

	});

	$(document).on('submit','form.form_inscription',function(e){
 		e.preventDefault();
 		console.log("envoi de form inscription");
 		var form = $(this);
 		$(".message").removeClass("alert-danger").removeClass("alert-success");
 		form.find("button[type='submit']").attr('disabled', 'disabled');

 		$.ajax( {
	    	type: form.attr('method'),
	      	url: form.attr('action'),
		    data: form.serialize(),
		    dataType : "json",
	      	success: function( response ) {
	      		if(response.code== 500){
	      			$(".message").addClass('alert alert-danger');
	      		}else{
	      			$(".message").addClass('alert alert-success');
	      		}
	      		$(".message").html(response.msg);
	      	},
	      	complete: function() {
 				form.find("button[type='submit']").removeAttr('disabled');
		    },
	      	error: function( response ) {
	      		form.find("button[type='submit']").removeAttr('disabled');	      		
	      	},
	    });

 	});


	$(document).on('submit','form#demande_vente,form#demande_callback',function(e){
 		e.preventDefault();
    	var id =  $(this).attr('id');
 		var form = $("#"+id);
 		form.find("button[type='submit']").attr('disabled', 'disabled');

 		$.ajax( {
	    	type: form.attr('method'),
	      	url: form.attr('action'),
		    data: form.serialize(),
	      	success: function( response ) {
	      		generateNotify("", "Votre demande a été prise en compte et sera traitée dès que possible." , "fa fa-check", "success");
				form.find("input").val("");
				//regegnerer le captcha

	      	},
	      	complete: function() {
 				form.find("button[type='submit']").removeAttr('disabled');
		    },
	      	error: function( response ) {
 				form.find("button[type='submit']").removeAttr('disabled');	      		
	        	generateNotify("", "Une erreur s'est produite lors de l'envoi de votre message. veuillez essayer à nouveau plus tard." , "fa fa-exclamation-triangle", "danger");
	      	},
	    });
	   
 	});

	grecaptcha.ready(function () {
        grecaptcha.execute('6LdfXu4ZAAAAAHVp6RpCfd5FivOtM1O9Oae7zAfF', { action: 'contact' }).then(function (token) {
        	console.log(token)
            $("#newsletter_inscrit .recaptcha").val(token)
        });
    });

    grecaptcha.ready(function () {
        grecaptcha.execute('6LdfXu4ZAAAAAHVp6RpCfd5FivOtM1O9Oae7zAfF', { action: 'contact' }).then(function (token) {
        	console.log(token);
            $("#newsletter_inscrit_mini .recaptcha").val(token)
        });
    });

    
	
 	$(document).on('submit','form#newsletter_inscrit, form#newsletter_inscrit_mini ',function(e){
 		e.preventDefault();
    	var id =  $(this).attr('id');
 		var form = $("#"+id);
 		form.find("button[type='submit']").attr('disabled', 'disabled');

 		$.ajax( {
	    	type: form.attr('method'),
	      	url: form.attr('action'),
		    data: form.serialize(),
	      	success: function( response ) {
	      		if(response.success == false){
	      			generateNotify("", response.msg , "fa fa-remove", "danger");

	      		}else{
	      			generateNotify("", response.msg , "fa fa-check", "success");
	      		}
				form.find("input").val("");

    			/*grecaptcha.ready(function () {
		        	grecaptcha.execute('6LdfXu4ZAAAAAHVp6RpCfd5FivOtM1O9Oae7zAfF', { action: 'contact' }).then(function (token) {
			            $("#"+id).find('recaptcha').val(token);
			        });
    			});*/


			},
	      	complete: function() {
      			grecaptcha.execute('6LdfXu4ZAAAAAHVp6RpCfd5FivOtM1O9Oae7zAfF', { action: 'contact' }).then(function (token) {
		            $("#"+id).find('recaptcha').val(token);
		            if(id == 'newsletter_inscrit'){
		            	$("#newsletter_inscrit_recaptcha_response").val(token);
		            }
		        });

 				form.find("button[type='submit']").removeAttr('disabled');
		    },
	      	error: function( response ) {
 				form.find("button[type='submit']").removeAttr('disabled');	      		
	        	generateNotify("", "Une erreur s'est produite, veuillez essayer à nouveau plus tard." , "fa fa-exclamation-triangle", "danger");
	      	},
	    });
	   
 	});

	$.CookiesMessage({
		messageText: "Ce site utilise des cookies fonctionnels et analytiques pour recueillir des informations sur l'utilisation de notre site Web, afin de vous offrir la meilleure expérience utilisateur possible.",
		messageBg: "#151515",								// Message box background color
		messageColor: "#FFFFFF",						// Message box text color
		messageLinkColor: "#02afe0",				// Message box links color
		closeEnable: true,									// Show the close icon
		closeColor: "#02afe0",							// Close icon color
		closeBgColor: "#000000",						// Close icon background color
		acceptEnable: true,									// Show the Accept button
		acceptText: "Accepter & Fermer",				// Accept button text
		infoEnable: true,										// Show the More Info button
		infoText: "En savoir plus",							// More Info button text
		infoUrl: "/mentions",												// More Info button URL
		cookieExpire: 180										// Cookie expire time (days)
	});



	        // data for "hot points"
        var dataTab = {
            0: 'Here is our office',
            1: 'The level is empty!',
            2: 'Here you can find our CEO',
        };
        
        // main program
        function render() {
            // canvas
            var canvas = document.getElementById('appCanvas');
            var context = canvas.getContext('2d');
            var circles = [];
            
            // keep functions here for better performance
            var theCircle = function(x, y, radius) {
                this.left = x - radius;
                this.top = y - radius;
                this.right = x + radius;
                this.bottom = y + radius;
            };
            
            var draw = function (context, x, y, fillcolor, radius, linewidth, strokestyle, fontcolor, textalign, fonttype, filltext) {
                context.beginPath();
                context.arc(x, y, radius, 0, 2 * Math.PI, false);
                context.fillStyle = fillcolor;
                context.fill();
                context.lineWidth = linewidth;
                context.strokeStyle = strokestyle;
                context.stroke();
                
                context.fillStyle = fontcolor;
                context.font = fonttype;
                context.textAlign = textalign;
                
                context.fillText(filltext, x, y);
            };
            
            var drawCircle = function (context, x, y, fillcolor, radius, linewidth, strokestyle, fontcolor, textalign, fonttype, filltext, circles) {
                draw(context, x, y, fillcolor, radius, linewidth, strokestyle, fontcolor, textalign, fonttype, filltext);
                var circle = new theCircle(x, y, radius);
                circles.push(circle);
            };
            
            // define our circles in right places
            drawCircle(context, 100, canvas.height / 2, "green", 20, 5, "#f00", "#fff", "center", "bold 30px Arial", "A", circles);
            drawCircle(context, 100, canvas.height - 54, "blue", 30, 5, "#ff0", "#fff", "center", "bold 30px Arial", "B", circles);
            drawCircle(context, 140, 170, "lime", 30, 5, "#f00", "#fff", "center", "bold 30px Arial", "C", circles);
            
            // click handler
            $('#appCanvas').click(function (e) {
                var clickedX = e.pageX - this.offsetLeft;
                var clickedY = e.pageY - this.offsetTop;
                
                for (var i = 0; i < circles.length; i++) {
                    if (clickedX < circles[i].right && clickedX > circles[i].left && clickedY > circles[i].top && clickedY < circles[i].bottom) {
                        // alert('You clicked: ' + (i + 1));
                        alert(dataTab[i]);
                    }
                }
            });
        }

        render();


});


$(document).on('click', ".renvoiAccess", function(e) {
	e.preventDefault();

	$('#dataSendAccesModal').modal('show');
    $('#dataSendAccesModal').find('.modal-body').empty();
    $('#dataSendAccesModal').find('.modal-body').append($(this).attr('data-message'));
    $("#dataSendOK").attr('data-url', $(this).attr('href'));
});

$(document).on('click', "#dataSendOK", function(e) {
	var url = $(this).attr('data-url');

	$.ajax({
	    url : url,
	    type : 'GET',
	    dataType : 'json',
	    success : function(json) {
	    	$('#dataSendAccesModal').find('.modal-body').empty();
    		$('#dataSendAccesModal').find('.modal-body').append(json.message);
    		$('#dataSendOK').addClass('hidden');
    		$('#dataSendAccesModal .btn-outline-danger').html("Fermer");
	    }
	});
});

$(document).on('hide.bs.modal', "#dataSendAccesModal", function(e) {
    $('#dataSendOK').removeClass('hidden');
    $('#dataSendAccesModal .btn-outline-danger').html("Annuler");
});

$(document).on('click','.delete',function(e){
	e.preventDefault();
	e.stopPropagation(); 		

	var message = $(this).attr('data-message');
	var href = $(this).attr('href');
	
	$('#dataConfirmModal').find('.modal-body p').text(message);
	$('#dataConfirmOK').attr('data-url',href);
	$('#dataConfirmModal').modal('show');

	$('#dataConfirmOK').click( function(){
		window.location = $(this).attr('data-url');
	});

	return false;
});

$(document).on('click','#dataConfirmOK',function(e){
	window.location = $(this).attr('data-url');
});

$(document).on('submit','form#sendReponse',function(e){
	e.preventDefault();
   	var form = $(this);
   	var url = $(this).attr('action');

   	$.ajax({
	    url : url,
	    type : 'POST',
	    data: form.serialize(),
	    dataType : 'json',
	    success : function(json) {
	    	$("#messages-reponses").empty();
	    	$("#messages-reponses").append(json.message);
	    	$("textarea[name='message']").val('');
	    	$('body, html, #messages-reponses').scrollTop(0);
	    }
	});

});





  var count = $('.b-temoignage').length;
  var index = 0;
  var myInterval = null;

  $('button').click(function() {
  
    myInterval = setInterval(function() {

      if (index > count) {
        clearInterval(myInterval);
      } else {
        $($('a.panel').get(index)).trigger("click");
        index++;
      }
    }, 1000);

  });


  
  $(function () {
    var currentIndex = 0, 
    	items = $('.b-temoignage'), 
    	itemAmt = items.length,
    	item = $('.b-temoignage').eq(0);

    function cycleItems() {
    	if(item != null){
    		item.addClass('d-none');
    	}
        
        item = $('.b-temoignage').eq(currentIndex);
        item.addClass('fading');
        item.removeClass('d-none');        
    }

    var autoSlide = setInterval(function () {
        currentIndex += 1;
        if (currentIndex > itemAmt - 1) {
            currentIndex = 0;
        }
        cycleItems();
    }, 14000);
});



$('.btn-retour').click(function() {
	window.history.back();
});